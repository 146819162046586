
@if $enable-dark-mode {
  @include color-mode(dark, true) {

    @each $color, $value in $grays-dark {
      --#{$prefix}gray-#{$color}: #{$value};
    }

    @each $color, $value in $grays-rgb-dark {
      --#{$prefix}gray-#{$color}-rgb: #{$value};
    }

    @each $color, $value in $theme-colors-dark {
      --#{$prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors-rgb-dark {
      --#{$prefix}#{$color}-rgb: #{$value};
    }
    @each $color, $value in $theme-color-shades-dark {
      --#{$prefix}#{$color}: #{$value};
    }

    @each $color, $value in $theme-color-shades-rgb-dark {
      --#{$prefix}#{$color}-rgb: #{$value};
    }

    --#{$prefix}quaternary-color: #{$body-quaternary-color-dark};
    --#{$prefix}quaternary-color-rgb: #{to-rgb($body-quaternary-color-dark)};
    --#{$prefix}quaternary-bg: #{$body-quaternary-bg-dark};
    --#{$prefix}quaternary-bg-rgb: #{to-rgb($body-quaternary-bg-dark)};

    --#{$prefix}emphasis-bg: #{$body-emphasis-bg-dark};  // black
    --#{$prefix}emphasis-bg-rgb: #{to-rgb($body-emphasis-bg-dark)};

    --#{$prefix}body-highlight-color: #{$body-highlight-color-dark};
    --#{$prefix}body-highlight-color-rgb: #{to-rgb($body-highlight-color-dark)};

    --#{$prefix}body-highlight-bg: #{$body-highlight-bg-dark};
    --#{$prefix}body-highlight-bg-rgb: #{to-rgb($body-highlight-bg-dark)};

    --#{$prefix}translucent-rgb: #{to-rgb($border-color-translucent-dark)};


    // globals
    --#{$prefix}input-group-addon-bg-global: #{$gray-900};
    --#{$prefix}dropdown-link-color-global: var(--#{$prefix}body-highlight-color);
    --#{$prefix}dropdown-link-hover-color-global: #{$white};
    --#{$prefix}nav-vertical-color-global: #{$gray-100};

 

    /* ------------------------------- navbar-vertical ------------------------------- */
    --#{$prefix}navbar-vertical-border-color: #{$gray-900};
    --#{$prefix}navbar-footer-border-color: #{$gray-900};

    /* --------------------------------- Avatar --------------------------------- */

    --#{$prefix}avatar-name-color: var(--#{$prefix}primary-light);


    /* --------------------------------- buttons -------------------------------- */
    .btn-reveal {
      --#{$prefix}btn-color: #{$gray-400};
    }

    .btn.active {
      --#{$prefix}btn-active-bg: var(--#{$prefix}secondary-bg);
      // --#{$prefix}btn-active-color: red; //need to check
    }

    // /* ----------------------------- phoenix-buttons ---------------------------- */

    @each $color, $value in $button-colors {
      $hover-color: var(--#{$prefix}#{$color}-light);
      .btn-phoenix-#{$color} {
        @if $color == 'secondary' {
          --#{$prefix}btn-color: #{$gray-400};
          --#{$prefix}btn-hover-color: #{$hover-color};
          --#{$prefix}btn-active-color: #{$gray-400};
        } @else {
          --#{$prefix}btn-color: #{map-get($theme-colors-dark, $color)};
        }
        // --#{$prefix}btn-hover-color: #{$hover-color};
        --#{$prefix}btn-bg: #{$body-bg-dark};
        --#{$prefix}btn-border-color: #{$gray-900};
        --#{$prefix}btn-hover-bg: var(--#{$prefix}body-highlight-bg);
        --#{$prefix}btn-active-bg: var(--#{$prefix}body-highlight-bg);
        --#{$prefix}btn-hover-border-color: #{$gray-900};
        --#{$prefix}btn-active-border-color: #{$gray-1000};
      }
    }

    .settings-panel .btn {
      --#{$prefix}btn-color: #{$gray-600};
    }

    //subtle buttons
    @each $color, $value in $button-colors {
      $text-color: map-get($theme-colors-dark, $color);
      $background: rgba(map-get($theme-colors, $color), 0.14);
      $hover-background: rgba(map-get($theme-colors, $color), 0.2);
      @if $color == 'secondary' {
        $text-color: $gray-300;
        $background: rgba($gray-900, 0.5);
        $hover-background: rgba($gray-900, 0.8);
      }
      .btn-subtle-#{$color} {
        @include button-variant(
          $background: $background,
          $border: $background,
          $color: $text-color,
          $hover-background: $hover-background,
          $hover-color: $text-color
        );
      }
    }

    /* ----------------------------- outline buttons ---------------------------- */

    @each $color, $value in $button-colors {
      // $hover-color: map-get($theme-hover-colors, $color);
      $hover-color: var(--#{$prefix}#{$color}-dark);
      .btn-outline-#{$color} {
        @if $color == 'secondary' {
          --#{$prefix}btn-color: #{$gray-300};
          --#{$prefix}btn-border-color: #{$gray-300};
        }
        else {
          --#{$prefix}btn-color: var(--#{$prefix}#{$color}-light);
          --#{$prefix}btn-border-color: var(--#{$prefix}#{$color}-light);
        }

      }
    }

    /* --------------------------------- list js -------------------------------- */

    --#{$prefix}list-sort-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS4xMjkzIDQuOTkwMjNDMC42OTEwMTYgNC45OTAyMyAwLjQ3MTg3NSA1LjUwODIgMC43OTA2MjUgNS44MDcwM0wzLjE2MTMzIDguMTc3NzNDMy4zNDA2MiA4LjM3Njk1IDMuNjM5NDUgOC4zNzY5NSAzLjgzODY3IDguMTc3NzNMNi4yMDkzNyA1LjgwNzAzQzYuNTA4MiA1LjUwODIgNi4yODkwNiA0Ljk5MDIzIDUuODcwNyA0Ljk5MDIzSDEuMTI5M1pNNi4yMDkzNyAyLjg5ODQ0TDMuODM4NjcgMC41Mjc3MzVDMy42Mzk0NSAwLjM0ODQzOCAzLjM0MDYyIDAuMzQ4NDM4IDMuMTYxMzMgMC41Mjc3MzVMMC43OTA2MjUgMi44OTg0NEMwLjQ3MTg3NSAzLjIxNzE5IDAuNjkxMDE2IDMuNzE1MjMgMS4xMjkzIDMuNzE1MjNINS44NzA3QzYuMjg5MDYgMy43MTUyMyA2LjUwODIgMy4yMTcxOSA2LjIwOTM3IDIuODk4NDRaIiBmaWxsPSIjM0U0NjVCIi8+Cjwvc3ZnPgo=');
    /* --------------------------------- tinymce -------------------------------- */
    --#{$prefix}tinymce-tox-tbtn-enabled-icon-color: var(--#{$prefix}body-highlight-color);

    /* -------------------------------- choices -------------------------------- */
    --#{$prefix}choices-item-bg: rgba(var(--#{$prefix}primary-light-rgb), 0.25);


    /* ---------------------------------- logo ---------------------------------- */
    --#{$prefix}logo-text: var(--#{$prefix}emphasis-color);

    /* ------------------------------- date-picker ------------------------------ */
    // --#{$prefix}flatpickr-calendar-input-bg: #{$input-bg};
    --#{$prefix}flatpickr-calendar-bg: #{map-get($grays, '1100')};
    --#{$prefix}flatpickr-current-month-color: var(--#{$prefix}body-highlight-color);
    --#{$prefix}flatpickr-weekday-color: var(--#{$prefix}body-highlight-color);
    --#{$prefix}flatpickr-calendar-day-hover-bg: var(--#{$prefix}body-highlight-bg);
    --#{$prefix}flatpickr-weekend-days-color: var(--#{$prefix}warning);
    --#{$prefix}flatpickr-today-color: #{$gray-900};
    --#{$prefix}flatpickr-calendar-day-selected-bg: #{rgba(var(--#{$prefix}primary-rgb), 0.2)};

    /* -------------------------------- Fullcalendar ------------------------------- */
    --#{$prefix}calendar-bg: #{$gray-1100};

    /* -------------------------------- offcanvas ------------------------------- */

    .offcanvas {
      --#{$prefix}offcanvas-bg: #{map-get($grays, '1100')};
    }

    /* ------------------------------ dark classes ------------------------------ */
    .dark__border-primary {
      --phoenix-border-color: #{map-get($theme-colors, 'primary')} !important;
    }

    /* ------------------------------- navbar-top  ------------------------------- */
    --#{$prefix}navbar-top-border-color: #{$gray-900};
    --#{$prefix}navbar-top-dropdown-color: #{$gray-600};

    [data-navbar-appearance="darker"] {
      --#{$prefix}navbar-top-dropdown-hover-bg: #{$gray-900};
    }

    /* ------------------------------- navbar-double top ------------------------------- */

    --#{$prefix}dual-nav-border-color: #{$dual-nav-border-color-dark};
    --#{$prefix}dual-nav-first-layer-bg: #{map-get($grays-dark, '50')};

    /* ------------------------------- navbar-vertical inverted ------------------------------- */

    // --#{$prefix}navbar-vertical-bg-color: #{$gray-1100};
    // --#{$prefix}navbar-vertical-label-color: #{$gray-600};
    --#{$prefix}navbar-vertical-link-color: #{$gray-400};
    --#{$prefix}navbar-vertical-link-hover-color: #{$gray-100};
    --#{$prefix}navbar-vertical-link-hover-bg: #{$gray-900};
    --#{$prefix}navbar-vertical-link-active-color: #{$white};
    --#{$prefix}navbar-vertical-link-disable-color: #{$gray-600};

    --#{$prefix}hamburger-menu-color: #{$body-quaternary-color-dark};
    // ----------- collapsed colors ----------------
    --#{$prefix}navbar-vertical-hr-color: #{$gray-800};
    --#{$prefix}navbar-vertical-collapsed-menu-bg: var(--#{$prefix}body-highlight-bg);

  
    /* -------------------------- theme-control-toggle -------------------------- */
    --#{$prefix}theme-control-toggle-bg: rgba(var(--#{$prefix}primary-rgb), 0.24);
    --#{$prefix}theme-control-toggle-hover-bg: rgba(var(--#{$prefix}primary-rgb), 0.7);
    --#{$prefix}theme-control-toggle-color: var(--#{$prefix}primary-light);
    --#{$prefix}theme-control-toggle-hover-color: #{map-get($theme-colors-bg-subtle,'primary')};

    /* -------------------------------- Scrollbar ------------------------------- */
    --#{$variable-prefix}scrollbar-bg: #{rgba($gray-900, 0.6)}; // need to fix


    /* -------------------------------- Kanban ------------------------------- */
    --#{$prefix}kanban-bg: #{$body-bg-dark};
    --#{$prefix}kanban-column-bg: var(--#{$prefix}emphasis-bg);
    --#{$prefix}kanban-ghost-bg: #{$body-bg-dark};
    --#{$prefix}kanban-drag-bg: #1c222c;
    --#{$prefix}kanban-column-hover-bg: #1c222c;

  
    --#{$prefix}leaflet-popup-content-wrapper-bg: #{$dark};

    // table variants
    @each $color, $value in $theme-colors {
      @if $color == 'dark' {
        .table-#{$color} {
          --#{$prefix}table-bg: $value;
        }
      } @else if $color == 'light' {
        .table-#{$color} {
          --#{$prefix}table-bg: #{map-get($grays, '100')};
        }
      } @else {
        .table-#{$color} {
          --#{$prefix}table-bg: #{shift-color($value, -$table-bg-scale)};
          --#{$prefix}table-color: #{color-contrast(opaque($gray-900, $value))};
          --#{$prefix}table-hover-bg: #{mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-hover-bg-factor))};
          --#{$prefix}table-hover-color: #{color-contrast(mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-hover-bg-factor)))};
          --#{$prefix}table-striped-bg: #{mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-striped-bg-factor))};
          --#{$prefix}table-striped-color: #{color-contrast(mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-striped-bg-factor)))};
          --#{$prefix}table-active-bg: #{mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-active-bg-factor))};
          --#{$prefix}table-active-color: #{color-contrast(mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-active-bg-factor)))};
          --#{$prefix}table-border-color: #{rgba(mix(color-contrast(opaque($gray-900, $value)),$value,percentage($table-border-factor)),0.05)};
        }
      }
    }
  }
}