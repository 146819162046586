// Wizard
.theme-wizard {
  .nav-item {
    position: relative;
    &:not(:last-child) {
      flex: 1;
    }

    &:first-child,
    &:last-child {
      .nav-item-circle-parent:before {
        width: 50%;
      }
    }
    &:first-child {
      .nav-item-circle-parent:before {
        transform: translate3d(0, -50%, 0);
      }
    }
    &:last-child {
      .nav-item-circle-parent:before {
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
    .nav-link {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      // text-align: center;
      color: var(--#{$prefix}tertiary-color);
      display: block;
      .nav-item-circle {
        color: var(--#{$prefix}tertiary-color);
        background-color: var(--#{$prefix}theme-wizard-nav-item-circle-bg);
        border: 2px solid var(--#{$prefix}border-color);
        height: 2.375rem;
        width: 2.375rem;
        border-radius: 50%;
        line-height: 2.25rem;
        padding: 0;
        text-align: center;
        align-self: center;
        display: inline-block;
        transition: $transition-base;
      }
      &.active {
        .nav-item-circle {
          color: var(--#{$prefix}theme-wizard-active-color);
          border-color: var(--#{$prefix}theme-wizard-active-color);
        }
        color: var(--#{$prefix}theme-wizard-active-color);
      }
      &.done {
        .nav-item-circle {
          background-color: var(
            --#{$prefix}theme-wizard-complete-color
          ) !important;
          color: #fff;
          border-color: var(--#{$prefix}theme-wizard-complete-color);
          .check-icon {
            display: inline-block;
          }
        }
        color: var(--#{$prefix}theme-wizard-complete-color);
        .nav-item-circle-parent {
          &:before {
            background-color: var(--#{$prefix}theme-wizard-complete-color);
          }
        }
      }
      .nav-item-circle {
        .check-icon {
          display: none;
        }
      }
      .nav-item-circle-parent {
        display: block;
      }
    }
    &:not(&:last-child) {
      .nav-link {
        &:before {
          content: '';
          height: 2px;
          width: 100%;
          position: absolute;
          left: 3%;
          top: 48%;
          background: rgba(var(--#{$prefix}quaternary-bg-rgb), 0.8);
          @include media-breakpoint-up(md) {

            top: 35%;
          }
        }
        &.done {
          &:before {
            background: var(--#{$prefix}theme-wizard-active-color) !important;
          }
        }
        &.complete {
          &:before {
            background: var(--#{$prefix}theme-wizard-complete-color) !important;
          }
        }
      }
    }

    .nav-item:not(:first-child) {
      .active {
        .nav-item-circle-parent {
          &:after {
            content: '';
            width: 50%;
            position: absolute;
            left: -1px;
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            background-color: var(--#{$prefix}theme-wizard-complete-color);
          }
        }
      }
    }
    .nav-item-circle-parent {
      position: relative;
      // padding: 0 map-get($spacers, 3);
    }
    .nav-item-circle {
      margin: 0 auto;
      z-index: 1;
      position: relative;
    }

    .pager {
      display: flex;
      justify-content: space-between;
    }
    .wizard-lottie-wrapper {
      height: 1px;
      position: relative;
      padding-bottom: 11.875rem;
      overflow: hidden;
      .wizard-lottie {
        width: 21.25rem;
        height: auto;
        transform: translateY(-73%);
      }
    }
  }
}
